.singlepage-menu-item {
  position: fixed;
  top: 50%;
  right: 0;
  width: 20px;
  background-color: rgb(2, 40, 2);
  color: #fff;
  height: 20px;
  padding: 0.8rem;
  box-shadow: -3px -3px 3px #0000008e;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.menu-icon {
  color: #fff;
}

.loader {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: 'Montserrat', Arial, sans-serif;
  font-weight: bold;
}
