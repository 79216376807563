@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Montserrat:ital,wght@0,200;0,300;0,400;0,500;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Sevillana&display=swap");

body {
  margin: 0;
  margin-top: 5%;
  background-color: #f8f5ef;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 60vh;
  justify-content: center;
  gap: 20px;
  opacity: 0.8;
  background-image:
    repeating-radial-gradient(
      circle at 0 0,
      transparent 0,
      #fff 10px
    ),
    repeating-linear-gradient(#c5decb55, #c5decb);
}

@media only screen and (min-width: 325px) and (max-width: 1024px) {
  body {
    padding-bottom: 30vh;

    /* other tablet styles */
  }
}
