@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Montserrat:ital,wght@0,200;0,300;0,400;0,500;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

.certificate-cont {
  display: flex;
  width: 200vh;
  margin: auto;
  overflow-y: hidden;
}

.cert-name {
  color: #0d381e;
  font-size: 1.8rem;
  font-family: "Montserrat", sans-serif;
  font-weight: bold;
  text-transform: capitalize;
  margin-top: 0;
  margin-bottom: 0.5rem;
}

.stud-num {
  text-transform: lowercase;
}

.side-design-cont {
  width: 20%;
}

.side-design {
  display: flex;
  flex-direction: column;
  image-resolution: 300dpi;
}

.certificate {
  background-color: #fff;
  display: flex;
  width: 140vh;
  height: 90vh;
  gap: 3px;
  font-weight: bold;
  font-family: "Montserrat", sans-serif;
  margin: auto;
}

.cert-data-section {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin: auto;
  width: 80%;
  margin-left: -5%;
  font-weight: bold;
}

.cert-title {
  font-size: 3vh;
  text-transform: uppercase;
  margin: 0;
  font-weight: 900;
}

.cert-awardedto {
  font-size: 2vh;
  margin: 1vh 0;
  text-align: center;
  text-transform: capitalize;
}

.cert-purpose {
  font-size: 0.8rem;
  margin: 1vh 0;
  text-align: center;
  text-transform: uppercase;
  padding-right: 15%;
  padding-left: 15%;
}

.stud-name {
  font-size: 4vh;
  font-weight: 600;
  margin: 0.7vh 0;
  display: flex;
  border-bottom: 2px solid #0d381e;
  text-align: center;
}

.duration {
  font-size: 2vh;
  margin-top: 1vh;
}

.personnel {
  display: flex;
  gap: 10px;
  align-items: center;
  margin-top: 2vh;
}

.personnel-signature {
  display: block;
  margin: auto;
}

.personnel-title {
  border-top: 2px solid #0d381e;
  font-weight: bold;
}

.signature {
  display: block;
  margin: auto;
}

.partners-logo-cont {
  display: flex;
  gap: 5vh;
}

.qr-code-cont {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0;
}

.qrcode-mobile {
  display: none;
}

.qr-code-cont > p {
  margin: 0;
}

.cert-num {
  text-transform: lowercase;
}

.footer {
  display: flex;
  gap: 15vh;
  margin-top: 1.5vh;
  justify-content: flex-end;
  font-weight: bold;
}

.cert-verify {
  font-size: 0.7rem;
  margin-top: -0.5vh;
}

.download-btn {
  background-color: #0d381e1d;
  margin: auto;
  display: block;
  margin-top: 2vh;
  border-radius: 2vh;
  border: none;
  padding: 1rem;
  color: #0d381e;
  font-weight: bold;
  font-family: 'Montserrat', Arial, sans-serif;
}

.download-btn:hover {
  background-color: #07672d1d;
}

@media only screen and (min-width: 325px) and (max-width: 1024px) {
  .certificate-cont {
    display: flex;
    width: 50vh;
    height: 40vh;
    margin: auto;
    overflow-y: hidden;
  }

  .cert-logo {
    width: 15%;
  }

  .cert-name {
    color: #0d381e;
    font-size: 0.4rem;
    font-family: "Montserrat", sans-serif;
    font-weight: bold;
    text-transform: capitalize;
    margin-top: 0;
    margin-bottom: 0.1rem;
  }

  .stud-num {
    text-transform: lowercase;
  }

  .side-design-cont {
    width: 20%;
  }

  .side-design {
    display: flex;
    flex-direction: column;
    image-resolution: 300dpi;
    width: 94%;
  }

  .certificate {
    background-color: #fff;
    display: flex;
    width: 50vh;
    height: 30vh;
    gap: 3px;
    font-weight: bold;
    font-family: "Montserrat", sans-serif;
    margin: auto;
  }

  .cert-data-section {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    margin: auto;
    margin-left: -5%;
    font-weight: bold;
    padding-right: 1rem;
  }

  .cert-title {
    font-size: 0.4rem;
    padding: 0 0.3rem;
    text-transform: uppercase;
    margin: 0;
    font-weight: 900;
  }

  .cert-awardedto {
    font-size: 0.3rem;
    margin: 0.1rem 0;
    text-align: center;
    text-transform: capitalize;
  }

  .cert-purpose {
    font-size: 0.3rem;
    margin: 1vh 0;
    text-align: center;
    text-transform: uppercase;
    padding-right: 15%;
    padding-left: 15%;
  }

  .stud-name {
    font-size: 0.5rem;
    font-weight: 600;
    margin: 0.7vh 0;
    display: flex;
    border-bottom: 1px solid #0d381e;
    text-align: center;
  }

  .duration {
    font-size: 0.4rem;
    margin-top: 0.3rem;
  }

  .personnel {
    display: flex;
    gap: 10px;
    align-items: center;
    margin-top: 0.5rem;
    width: 80%;
  }

  .personnel-title {
    border-top: 1px solid #0d381e;
    font-weight: bold;
  }

  .personnel-signature {
    display: block;
    width: 5%;
    margin: auto;
  }

  .signature {
    display: block;
    margin: auto;
    width: 50%;
  }

  .partners-logo-cont {
    display: flex;
    width: 40%;
  }

  .qr-code-cont {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0;
  }

  .qrcode-desktop {
    display: none;
  }

  .qrcode-mobile {
    display: flex;
  }

  .qr-code-cont > p {
    margin: 0;
  }

  .cert-num {
    text-transform: lowercase;
    font-size: 0.4rem;
  }

  .footer {
    display: flex;
    gap: 0.5rem;
    margin-top: 0.5rem;
    justify-content: flex-end;
    font-weight: bold;
    padding-right: 1rem;
  }

  .cert-verify {
    font-size: 0.3rem;
    margin-top: -0.5vh;
    text-align: center;
  }

  .download-btn {
    background-color: #0d381e1d;
    margin: auto;
    display: block;
    margin-top: 2vh;
    border-radius: 2vh;
    border: none;
    padding: 1rem;
    color: #0d381e;
    font-weight: bold;
    font-family: 'Montserrat', Arial, sans-serif;
  }

  .download-btn:hover {
    background-color: #07672d1d;
  }
}
